// src/App.js

import React, { useEffect, useState } from 'react';
import Dashboard from './Dashboard';
import Cookies from 'js-cookie';

async function fetchUserData(sessionToken) {
  try {
    const response = await fetch('https://syncratech-infrastructure.azurewebsites.net/api/get-userworkspaceinfo', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${sessionToken}`, // Pass the JWT token in the Authorization header
        'Content-Type': 'application/json',
        'workspace': window.location.href
      },
    });

    if (!response.ok) {
      return null;
    }

    const data = await response.json();
    return data;
  } catch (error) {
    return null;
  }
}

function App() {
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const sessionToken = Cookies.get('syncratech-shared.session-token');

    if (!sessionToken) {
      // Redirect to login if not authenticated
      window.location.href = 'https://reg.syncratech.io';
      return;
    }

    // Fetch user data using the session token
    fetchUserData(sessionToken).then(data => {
      if (data) {
        setUserData(data); // Set the fetched user data
      } else {
        // If the data is not successfully fetched, redirect to login
        window.location.href = 'https://reg.syncratech.io';
      }
      setIsLoading(false); // Set loading state to false after fetching
    });
  }, []);

  if (isLoading) {
    return <div>Loading...</div>; // Show a loading indicator while fetching data
  }

  // If the token is valid and user data is fetched, render the main app
  return (
    <div className="App">
      {userData ? <Dashboard userData={userData} /> : <div>Failed to load data.</div>}
    </div>
  );
}

export default App;
