// src/ApplicationCard.js
import React from 'react';

function ApplicationCard({ application }) {
  // Add https:// in front of the URL if it doesn't already start with http:// or https://
  const applicationUrl = application.url.startsWith('http://') || application.url.startsWith('https://')
    ? application.url
    : `https://${application.url}`;

  return (
    <a
      href={applicationUrl}
      target="_blank" // Opens the link in a new tab
      rel="noopener noreferrer" // Security measure to prevent access to the window object
      className="block bg-gray-200 dark:bg-gray-700 p-4 rounded shadow hover:bg-gray-300 dark:hover:bg-gray-600 cursor-pointer"
    >
      <h3 className="font-bold text-base dark:text-white">{application.name}</h3>
      <p className="text-sm dark:text-gray-300">{application.description}</p>
    </a>
  );
}

export default ApplicationCard;
