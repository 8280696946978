// src/Sidebar.js
import React from 'react';

function Sidebar({ id, isOpen, onClose, title, children }) {
  return (
    <div
      key={id}
      className={`fixed top-0 right-0 h-full bg-white dark:bg-gray-800 shadow-lg transition-transform transform ${
        isOpen ? 'translate-x-0' : 'translate-x-full'
      } w-full sm:w-1/3 z-30`}
    >
      <div className="flex items-center justify-between p-4 border-b border-gray-300 dark:border-gray-700">
        <h2 className="text-lg font-bold">{title}</h2>
        <button onClick={onClose} className="text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-gray-100">
          &times;
        </button>
      </div>
      <div className="p-4">{children}</div>
    </div>
  );
}

export default Sidebar;
