// src/WorkspaceDropdown.js
import React, { useState } from 'react';

function WorkspaceDropdown({ currentWorkspace, workspaces, onWorkspaceSwitch }) {
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <div className="relative">
      <button
        onClick={() => setShowDropdown(!showDropdown)}
        className="text-lg font-semibold flex items-center space-x-1"
      >
        <span>{currentWorkspace.name}</span>
        <svg
          className={`w-4 h-4 transform ${showDropdown ? 'rotate-180' : ''}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
        </svg>
      </button>

      {showDropdown && (
        <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 rounded-md shadow-lg z-20">
          <ul className="py-1">
            {workspaces.map((workspace) => (
              <li key={workspace.id}>
                <button
                  onClick={() => {
                    setShowDropdown(false);
                    onWorkspaceSwitch(workspace);
                  }}
                  className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 w-full text-left"
                >
                  {workspace.name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default WorkspaceDropdown;
