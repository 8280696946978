import React, { useState } from 'react';
import WorkspaceDropdown from './WorkspaceDropdown';
import UserDropdown from './UserDropdown';
import Sidebar from './Sidebar'; // Import Sidebar component
import UserCard from './UserCard';
import Cookies from 'js-cookie';

function Header({ currentWorkspace, workspaces, onWorkspaceSwitch, userName, users }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSidebarAddUserOpen, setIsSidebarAddUserOpen] = useState(false);
  const [memberName, setMemberName] = useState();
  const [memberEmail, setMemberEmail] = useState();
  const [isMemberSubmitting, setIsMemberSubmitting] = useState(false);

  const handleManageUsers = () => {
    setIsSidebarOpen(true); // Open the Sidebar when "Manage users" is clicked
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false); // Close the Sidebar when the close button is clicked
  };

  const handleAddUser = () => {
    setIsSidebarAddUserOpen(true);
  };

  const handleCloseAddUserSidebar = () => {
    setIsSidebarAddUserOpen(false);
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsMemberSubmitting(true);
  
    // Retrieve the session token from the cookie
    const sessionToken = Cookies.get('syncratech-shared.session-token');
  
    if (!sessionToken) {
      setIsMemberSubmitting(false);
      return;
    }
  
    try {
      const response = await fetch('https://syncratech-infrastructure.azurewebsites.net/api/post-addusertoworkspace', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionToken}`, // Use the retrieved session token
          'workspace': window.location.href,
        },
        body: JSON.stringify({
          name: memberName,
          email: memberEmail,
        }),
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Failed to add user:', errorText);
        alert('Failed to add user. Please try again.');
      } else {
        const responseData = await response.json();
        console.log('User added successfully:', responseData);
        handleCloseAddUserSidebar(); // Close the sidebar after successful submission
      }
    } catch (error) {
      console.error('Error adding user:', error);
    } finally {
      setIsMemberSubmitting(false);
    }
  };

  return (
    <header className="flex items-center justify-between p-4 bg-white dark:bg-gray-800 shadow-md">
      <div className="flex items-center space-x-2">
        <img src="/logo.png" alt="SyncraTech Logo" className="h-8" />
        <span className="text-xl font-bold">SyncraTech</span>
      </div>

      <WorkspaceDropdown
        currentWorkspace={currentWorkspace}
        workspaces={workspaces}
        onWorkspaceSwitch={onWorkspaceSwitch}
      />

      <UserDropdown userName={userName} onManageUsers={handleManageUsers} onAddUser={handleAddUser} />

      {/* Sidebar component */}
      <Sidebar key="sidebar-manager-users" id="sidebar-manager-users" isOpen={isSidebarOpen} onClose={handleCloseSidebar} title="Manage Users">
        <section className="grid gap-4 grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1">
          {users.map(user => {
            return <UserCard key={user.id} user={user} />;
          })}
        </section>
      </Sidebar>
      <Sidebar key="sidebar-add-user" id="sidebar-add-user" isOpen={isSidebarAddUserOpen} onClose={handleCloseAddUserSidebar} title="Add new member">
        {/* Form for creating a new user */}
        <form onSubmit={handleFormSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Member Name</label>
            <input
              type="text"
              className="mt-1 p-2 text-black w-full border border-gray-300 dark:border-gray-600 rounded focus:outline-none focus:ring focus:ring-blue-300 dark:focus:ring-blue-600"
              placeholder="Enter member name"
              value={memberName}
              onChange={(e) => setMemberName(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Email</label>
            <input
              type="text"
              className="mt-1 p-2 text-black w-full border border-gray-300 dark:border-gray-600 rounded focus:outline-none focus:ring focus:ring-blue-300 dark:focus:ring-blue-600"
              placeholder="Enter member email"
              value={memberEmail}
              onChange={(e) => setMemberEmail(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            className="w-full py-2 px-4 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-300 dark:focus:ring-blue-600"
            disabled={isMemberSubmitting}
          >
            {isMemberSubmitting ? 'Adding...' : 'Add member'}
          </button>
        </form>
      </Sidebar>
    </header>
  );
}

export default Header;
