// src/Dashboard.js
import React from 'react';
import Header from './components/Header';
import ApplicationCard from './components/ApplicationCard';
import CreateApplicationCard from './components/CreateApplicationCard';

function Dashboard({ userData }) {
  const handleWorkspaceSwitch = (workspace) => {
    window.location.href = `https://${workspace.url}`;
  };

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-gray-100">
      <Header
        currentWorkspace={userData.currentWorkspace}
        workspaces={userData.workspaces}
        onWorkspaceSwitch={handleWorkspaceSwitch}
        userName={userData.user.name}
        users={userData.users}
      />

      <main className="p-6">
        <section className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {userData.applications.map((application, index) => (
            <ApplicationCard key={index} application={application} />
          ))}
          <div>
            <CreateApplicationCard userData={userData} />
          </div>
        </section>
      </main>
    </div>
  );
}

export default Dashboard;
