import React, { useState } from 'react';

function UserDropdown({ userName, onManageUsers, onAddUser }) {
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <div className="relative">
      <button
        onClick={() => setShowDropdown(!showDropdown)}
        className="text-sm font-medium flex items-center space-x-1"
      >
        <span>{userName}</span>
        <svg
          className={`w-4 h-4 transform ${showDropdown ? 'rotate-180' : ''}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
        </svg>
      </button>

      {showDropdown && (
        <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 rounded-md shadow-lg z-20">
          <ul className="py-1">
            <li key="manage-members">
              <button
                onClick={onManageUsers}
                className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 w-full text-left"
              >
                Manage Members
              </button>
            </li>
            <li key="add-new-member">
              <button
                onClick={onAddUser}
                className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 w-full text-left"
              >
                Add New Member
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default UserDropdown;
