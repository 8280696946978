import React from 'react';

function UserCard({ user }) {
  return (
    <div
      className="block bg-gray-200 dark:bg-gray-700 p-4 rounded shadow hover:bg-gray-300 dark:hover:bg-gray-600 cursor-pointer flex justify-between items-center"
    >
      <div>
        <h3 className="font-bold text-base dark:text-white">{user.name}</h3>
        <p className="text-sm dark:text-gray-300">{user.email}</p>
      </div>
      
      {user.is_owner && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="yellow"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="yellow"
          className="w-6 h-6"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 17.27l4.15 2.18a1 1 0 001.45-1.05l-.79-4.61L19.8 9.9a1 1 0 00-.56-1.7l-4.65-.68L12 3.1a1 1 0 00-1.79 0l-2.08 4.42-4.65.68a1 1 0 00-.56 1.7l3.37 3.29-.79 4.61a1 1 0 001.45 1.05L12 17.27z" />
        </svg>
      )}
    </div>
  );
}

export default UserCard;
