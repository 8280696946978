// src/CreateApplicationCard.js
import React, { useState } from 'react';
import Sidebar from './Sidebar';
import Cookies from 'js-cookie';

function CreateApplicationCard({userData}) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [applicationName, setApplicationName] = useState('');
  const [description, setDescription] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCreateApplicationClick = () => {
    setIsSidebarOpen(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
    setApplicationName('');
    setDescription('');
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
  
    // Retrieve the session token from the cookie
    const sessionToken = Cookies.get('syncratech-shared.session-token');
  
    if (!sessionToken) {
      setIsSubmitting(false);
      return;
    }
  
    try {
      const response = await fetch('https://syncratech-infrastructure.azurewebsites.net/api/post-createapplication', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionToken}`, // Use the retrieved session token
          'workspace': userData.currentWorkspace.url,
        },
        body: JSON.stringify({
          application_name: applicationName,
          description: description,
        }),
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Failed to create application:', errorText);
        alert('Failed to create application. Please try again.');
      } else {
        const responseData = await response.json();
        console.log('Application created successfully:', responseData);
        handleCloseSidebar(); // Close the sidebar after successful submission
      }
    } catch (error) {
      console.error('Error creating application:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div onClick={handleCreateApplicationClick} className="flex items-center justify-center bg-gray-200 dark:bg-gray-700 p-4 rounded shadow hover:bg-gray-300 dark:hover:bg-gray-600 cursor-pointer">
        <span className="text-lg font-bold">+ Create a new application</span>
      </div>
      {/* Sidebar */}
      <Sidebar isOpen={isSidebarOpen} onClose={handleCloseSidebar} title="Create new application">
        {/* Form for creating a new application */}
        <form onSubmit={handleFormSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Application Name</label>
            <input
              type="text"
              className="mt-1 p-2 text-black w-full border border-gray-300 dark:border-gray-600 rounded focus:outline-none focus:ring focus:ring-blue-300 dark:focus:ring-blue-600"
              placeholder="Enter application name"
              value={applicationName}
              onChange={(e) => setApplicationName(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Description</label>
            <textarea
              className="mt-1 p-2 text-black w-full border border-gray-300 dark:border-gray-600 rounded focus:outline-none focus:ring focus:ring-blue-300 dark:focus:ring-blue-600"
              rows="4"
              placeholder="Enter description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-full py-2 px-4 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-300 dark:focus:ring-blue-600"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Creating...' : 'Create Application'}
          </button>
        </form>
      </Sidebar>
    </>
  );
}

export default CreateApplicationCard;
